<template>
  <!-- 议事协商详情 -->
  <div class="container">
    <div class="head fx_ai">
      <van-tag
        size="medium"
        :color="infoData.status == '2' ? '#FBE7E6' : '#DAF5DC'"
        :text-color="infoData.status == '2' ? '#E35D58' : '#0DB918'"
        >{{ infoData.status == "2" ? "已结束" : "进行中" }}</van-tag
      >
      <div class="title van-ellipsis fx1">{{ infoData.title }}</div>
    </div>
    <div class="contentDesc">{{ infoData.contentDesc }}</div>
    <div class="images fx_ai">
      <van-image
        v-for="(item, index) in imgList"
        width="2.71rem"
        height="2.71rem"
        fit="cover"
        class="imgItem"
        :src="item"
        :key="index"
        @click="imgPreview(index)"
      />
    </div>
    <div class="info_time fx_ai">
      <div class="time fx1">{{ infoData.startTime }}</div>
      <div class="number">共{{ infoData.partakeNumber }}位居民参与</div>
    </div>
    <!-- 评论 -->
    <div class="layouter">
      <div class="search-box">
        <input
          type="text"
          name="search"
          class="search-inpt"
          :placeholder="placeholder"
          @blur="onBlur"
          v-model="content"
          ref="input"
        />
        <van-icon name="upgrade" @click="upgrade" :disabled="upgraded" />
      </div>
      <!-- 评论列表 -->
      <van-skeleton v-if="currentList == -1" title :row="3" />
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getComment"
      >
        <div v-for="item in list" :key="item.id">
          <div class="comment_list fx" >
            <van-image
              width="1.2rem"
              height="1.2rem"
              fit="cover"
              round
              :src="item.createUserAvatar"
            />
            <div class="right fx1">
              <div class="top fx_ai">
                <div class="nick_name van-ellipsis fx1">
                  <span class="gray">{{ item.createUserName }}</span>
                  <span v-if="item.commentLevel == 2">
                    &nbsp;回复&nbsp;
                  </span>
                  <span class="gray">{{ item.replyUserName }}</span>
                </div>
                <div class="time">
                  {{ item.createTime }}&nbsp;
                  <van-icon name="delete-o" v-if="item.createUser == userInfo.userId" @click="removeComment(item.id)" />
                </div>
              </div>
              <div class="centent">{{ item.content }}</div>
              <div class="bottom" @click="toComment(item)">回复</div>
            </div>
          </div>
          <div
            class="comment_list fx second"
            v-for="val in item.sonList"
            :key="val.id"
          >
            <van-image
              width="1.2rem"
              height="1.2rem"
              fit="cover"
              round
              :src="val.createUserAvatar"
            />
            <div class="right fx1">
              <div class="top fx_ai">
                <div class="nick_name van-ellipsis fx1">
                  <span class="gray">{{ val.createUserName }}</span>
                  <span v-if="val.commentLevel == 2">
                    &nbsp;回复&nbsp;
                  </span>
                  <span class="gray">{{ val.replyUserName }}</span>
                </div>
                <div class="time">
                  {{ val.createTime }}&nbsp;
                  <van-icon v-if="item.createUser == userInfo.userId" name="delete-o" @click="removeComment(item.id)" />
                </div>
              </div>
              <div class="centent">{{ val.content }}</div>
              <div class="bottom" @click="toComment(val)">回复</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getDeatil, discussioncomment, disSubmit, remove } from "@/api/discussionApi";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      infoData: {},
      commentData: {},
      imgList: [],
      list: [],
      loading: false,
      finished: false,
      currentList: -1,
      page: 1,
      upgraded: false,
      userInfo: this.$store.getters.userInfo,
      placeholder: "添加议事评论",
      content: "",
    };
  },
  created() {
    this.getInfo(this.$route.query.id);
  },
  mounted() {},
  methods: {
    getInfo(id) {
      this.$store.commit("SET_IS_LOADING", true);
      getDeatil({
        id: id,
        openid: this.$store.getters.openId,
        tenantId: this.$store.getters.userInfo.tenantId,
      }).then((res) => {
        this.$store.commit("SET_IS_LOADING", false);
        this.infoData = res.data;
        this.imgList = res.data.picUrls.split(",");
      });
    },
    // 点击评论
    toComment(row){
      this.commentData = row
      this.placeholder = '回复@'+row.createUserName
      this.$refs.input.focus()
    },
    // 失去焦点
    onBlur() {
      if(!this.content){
        this.placeholder = '添加议事评论'
        this.commentData = {}
      }
    },
    // 评论
    upgrade() {
      this.upgraded = true;
      let formData = {
        discussionId: this.$route.query.id,
        tenantId: this.$store.getters.userInfo.tenantId,
        openid: this.$store.getters.openId,
        content: this.content
      };
      if (this.commentData.id) {
        formData.replyUserId = this.commentData.createUser;
        formData.commentLevel = 2;
        formData.parentId = this.commentData.parentId||this.commentData.id;
      }
      else{
        formData.commentLevel = 1;
      }
      disSubmit(formData).then((res) => {
        this.upgraded = false;
        this.commentData = {}
        this.content = ""
        this.$toast(res.msg);
        this.getNewComment()
      });
    },
    // 删除评论
    removeComment(ids) {
      remove({
        ids,
        tenantId: this.$store.getters.userInfo.tenantId,
      }).then((res) => {
        this.$toast(res.msg);
        this.getNewComment()
      });
    },
    // 刷新列表
    getNewComment() {
      this.page = 1
      this.currentList = -1
      this.list = []
      this.finished = false
      this.getComment()
    },
    // 评论列表
    getComment() {
      if (this.currentList !== 0) {
        discussioncomment({
          discussionId: this.$route.query.id,
          openid: this.$store.getters.openId,
          tenantId: this.$store.getters.userInfo.tenantId,
          current: this.page,
          size: 10,
          title: this.title,
        }).then((res) => {
          this.loading = false;
          if (res.code === 200) {
            this.currentList = res.data.records ? res.data.records.length : 0;
            this.page++;
            this.list = this.list.concat(res.data.records);
          }
        });
      } else {
        this.finished = true;
      }
    },
    // 预览
    imgPreview(index) {
      ImagePreview({
        images: this.imgList,
        startPosition: index,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  font-size: 14px;
}

.head {
  margin-top: 20px;

  .title {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.contentDesc {
  margin-top: 15px;
  min-height: 200px;
}

.images {
  flex-wrap: wrap;
  gap: 20px;
}

.info_time {
  margin-top: 10px;
  color: #999;

  .number {
    font-size: 12px;
  }
}

.layouter {
  padding: 16px 0;
}

.search-box {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 0.32rem;
  border-radius: 0.32rem;
  margin-bottom: 8px;
}

.search-inpt {
  flex: 1;
  font-size: 13px;
  background: none;
  outline: none;
  border: none;
  height: 36px;
}

.search-inpt::placeholder {
  color: #999;
}

.comment_list {
  &.second {
    margin-left: 10px;
  }

  border-bottom: 1px solid #eeeeee;
  padding: 12px 0;

  .right {
    margin-left: 10px;

    .top {
      margin-bottom: 8px;
    }

    .time {
      font-size: 12px;
      color: #999;
      margin-right: 10px;
    }

    .nick_name {
      font-size: 12px;
      width: 80px;
    }

    .bottom {
      width: 35px;
      color: #999;
    }
  }
}

.gray {
  color: #999;
}
</style>
  